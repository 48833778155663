import { GetTypeByBackendLabelKey } from '@features/backend-label/backend-label.type';
import { ISession } from '@features/sessions/session.interface';
import { Money } from '@utils/money/money.type';
import { InvoiceSummaryTypes } from '../invoice-summary-type.type';
import { IInvoiceSummary } from '../invoice-summary.interface';

type SharedSessionFields = Pick<
  ISession,
  'id' | 'startMoment' | 'duration' | 'isCancelled' | 'cancellationReason' | 'isPaid' | 'funderInvoice'
>;

export interface ISessionInvoiceSummary extends SharedSessionFields {
  invoiceSummaryType: typeof InvoiceSummaryTypes.Session;
  customerId: number;
  orderId: number;
  customerFullName: string;
  tutorFullName: string;
  customerCrn: string;
  hourlyRate: Money;
  dipReference: string | null;
  organisationId: GetTypeByBackendLabelKey<'organisationOptions'>;
  timesheetPath: string | null;
  supplierInvoice: string | null;
}

export type ISessionInvoiceSummaryDTO = Omit<ISessionInvoiceSummary, 'invoiceSummaryType'>;

export function isSessionInvoice(invoice: IInvoiceSummary): invoice is ISessionInvoiceSummary {
  return invoice.invoiceSummaryType === InvoiceSummaryTypes.Session;
}
